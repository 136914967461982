









































































































































































import { Api, MerchPurchase, MerchPurchaseFilters, MerchPurchaseStatus, PagedResults, TableState, SimpleSchool, StoreItemCategory, StoreItemFilter, StoreItemSubject, SalesPerson } from '@/edshed-common/api'
import moment from 'moment'
import Component from 'vue-class-component'
import ComponentHelper from '@/mixins/ComponentHelper'
import debounce from 'lodash/debounce'
import MerchSale from '@/components/views/components/MerchSale.vue'
import { Mixins, Watch } from 'vue-property-decorator'
import { SalesSourceDepts } from '../../edshed-common/api/types/sales'

@Component({
  name: 'MerchSales',
  components: { MerchSale }
})
export default class MerchSales extends Mixins(ComponentHelper) {
  loading: boolean = false

  salesData: PagedResults<MerchPurchase> = {
    items: [],
    total: 0
  }

  itemFilterData: StoreItemFilter[] = []
  filteredTags: StoreItemFilter[] = []
  selectedItems: StoreItemFilter[] = []

  @Watch('selectedItems')
  updateFilterItems () {
    this.filters.items = this.selectedItems?.map((item: StoreItemFilter) => Number(item.id))
  }

  table: TableState = {
    page: 1,
    perPage: 10,
    sort: 'timestamp',
    dir: 'desc',
    term: ''
  }

  filters: MerchPurchaseFilters = {
    categories: [],
    subjects: [],
    items: [],
    sale_id: undefined,
    school_id: undefined,
    status: [],
    unpaid: false
  }

  schoolSearchResults: SimpleSchool[] = []
  schoolSearchTerm: string = ''
  isFetching: boolean = false

  MerchPurchaseStatus = MerchPurchaseStatus

  StoreItemCategory = StoreItemCategory

  StoreItemSubject = StoreItemSubject

  persons : SalesPerson[] = []
  depts = SalesSourceDepts

  mounted () {
    moment.locale(this.$store.state.user.locale)

    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    } else {
      this.getSalesPersons()
      this.getMerchSalesInfo()
      this.getProductFilters()
    }
  }

  getFilteredItems (text: string) {
    this.filteredTags = this.itemFilterData.filter((option) => {
      return option.name.toLowerCase().includes(text.toLowerCase())
    })
  }

  async tableChanged (table: TableState) {
    this.table = table
    await this.getMerchSalesInfo()
  }

  searchSchoolsDebounced = debounce((school) => {
    this.searchSchools(school)
  }, 500)

  searchSchools (school: string) {
    if (!school.length) {
      this.schoolSearchResults = []
    }

    this.isFetching = true

    Api.searchSchools(school, false)
      .then((response) => {
        this.schoolSearchResults = response
      })
      .catch((err) => {
        console.log(err)
        this.schoolSearchResults = []
        throw err
      })
      .finally(() => {
        this.isFetching = false
      })
  }

  async getMerchSalesInfo () {
    try {
      this.loading = true
      this.salesData = await Api.getAllMerchSales({ skip: (this.table.page - 1) * this.table.perPage, take: this.table.perPage, sort: this.table.sort, dir: this.table.dir, term: this.table.term }, this.filters)
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load merch sales',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loading = false
    }
  }

  async getSalesPersons () {
    try {
      const sales_persons = await Api.getSalesPersons({ }, { active: 'Active' })
      this.persons = sales_persons.items
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load sales persons information',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async getProductFilters () {
    try {
      this.itemFilterData = await Api.getStoreItemFilters()
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not get Filters for products',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  clearFilters () {
    this.selectedItems = []
    this.schoolSearchTerm = ''

    this.filters = {
      categories: [],
      subjects: [],
      items: [],
      sale_id: undefined,
      school_id: undefined,
      status: [],
      unpaid: false
    }

    this.getMerchSalesInfo()
  }
}

import { render, staticRenderFns } from "./MerchSales.vue?vue&type=template&id=18dd7ba8&scoped=true&"
import script from "./MerchSales.vue?vue&type=script&lang=ts&"
export * from "./MerchSales.vue?vue&type=script&lang=ts&"
import style0 from "./MerchSales.vue?vue&type=style&index=0&id=18dd7ba8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18dd7ba8",
  null
  
)

export default component.exports